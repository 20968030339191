import { Box, Button, Divider, Skeleton, Typography } from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import JournalLines from './JournalLines'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IJournal } from '../../interface/IJournal'
import { IUser } from '../../interface/IUser'
import { format } from 'date-fns'
import { de } from 'date-fns/locale/de'

const JournalHeader = (props: any) => {
    const { t } = useTranslation()
    const journal: IJournal = useSelector((state: any) => state.journal?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    const formatDate = () => {
        return format(new Date(journal?.header?.date ?? new Date().toISOString()), 'cccccc. dd.MM.yyyy', {
            locale: de,
        })
    }

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="start" gap="16px" justifyContent="space-between" width="100%" sx={{ '@media (min-width: 700px)': { flexDirection: 'row', alignItems: 'end', gap: '0' } }}>
                {!loadingDate && user ? (
                    <Typography variant="h4">
                        {formatDate()} | ({user?.resource?.mserp_resourceid}) { /* getStatusChip(selectedDate?.header?.status || '') */ }
                    </Typography>
                ) : (
                    <Typography variant="h4">
                        <Skeleton variant="text" sx={{ width: { xs: 200, md: 300 }, display: 'inline-block' }} />
                    </Typography>
                )}
                <Button variant="outlined" onClick={undefined} startIcon={<RestartAltIcon />} color="error" disabled={false}>
                    {t('zdeheader.resetDay')}
                </Button>
            </Box>
            <Divider sx={{ marginTop: '16px' }} />

            <JournalLines />
        </>
    )
}

export default JournalHeader
