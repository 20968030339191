import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: localStorage.getItem('theme') ?? 'light',
}

export const themeSlice: any = createSlice({
    name: 'THEME',
    initialState,
    reducers: {
        setTheme: (state: any, action: any) => {
            state.value = action.payload
            localStorage.setItem('theme', action.payload)
        },
    },
})

export const { setTheme } = themeSlice.actions

export default themeSlice.reducer
