import { Skeleton, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ITimecard } from '../interface/ISelectedDate'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'

const ViolationComment = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    const changeViolationComment = (fieldName: string) => (event: any) => {
        var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
        const newValue = event.target.value

        if (newselectedDate !== undefined && newselectedDate.header !== undefined && newValue !== undefined) {
            newselectedDate.header[fieldName] = newValue
        }
        dispatch(setSelectedDate(newselectedDate))
    }

    return (
        <>
            <Typography>{t('zdeheader.violationComment')}</Typography>
            {!loadingDate ? (
                <TextField multiline rows={4} defaultValue={selectedDate?.header?.mserp_mbviolationcomment} onChange={changeViolationComment('mserp_mbviolationcomment')} disabled={selectedDate?.header?.isTransferred} style={{ width: 500, maxWidth: '100%' }} />
            ) : (
                <Skeleton variant="text" style={{ width: 500, maxWidth: '100%', height: 125 }} />
            )}
        </>
    )
}

export default ViolationComment
