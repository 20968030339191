import CircleIcon from '@mui/icons-material/Circle'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import { Box, Button, Skeleton, Tooltip, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { format, set } from 'date-fns'
import { useMsal } from '@azure/msal-react'
import { UpdateStoreHelper } from '../source/StoreHelper'
import { useDispatch, useSelector } from 'react-redux'
import { DayCalendarSkeleton, PickersDay } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import axios, { AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { setLoadingCalendar } from '../source/slice/LoadingDateSlice'
import { setProjects } from '../source/slice/ProjectsSlice'
import { ICalendar } from '../interface/ICalendar'
import { IProjects } from '../interface/IProjects'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { cloneDeep, isEqual } from 'lodash'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { UpdateStoreHelperJournal } from '../source/StoreHelperJournal'
import { IUser } from '../interface/IUser'
import { IJournal } from '../interface/IJournal'

const Calendar = () => {
    const { instance, accounts } = useMsal()
    const { t, i18n } = useTranslation()
    //const user = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const loadingCalendar: boolean = useSelector((state: any) => state.loadingDate?.loadingCalendar)
    const dispatch = useDispatch()
    const [calendarInit, setCalendarInit] = useState(false)

    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const selectedJournal: IJournal = useSelector((state:any)=> state.journal?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)

    const onDateChange = (value: Date) => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                if (user.worker && user.worker.mserp_usetimecard) {
                    UpdateStoreHelper(format(value, 'yyyy-MM-dd'), accessToken)
                } else {
                    UpdateStoreHelperJournal(format(value, 'yyyy-MM-dd'), accessToken)
                }
                const firstDayOfMonth = new Date(value.getFullYear(), value.getMonth(), 1)
                loadCalendar(firstDayOfMonth)
                getProjects(format(value, 'yyyy-MM-dd'))
                // loadCalendarDate(value)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const theme = useTheme()

    const accessTokenRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
    }

    const [calendarDates, setCalendarDates] = useState<ICalendar[] | undefined>(undefined)

    const loadCalendar = (month: Date) => {
        const getSelectedDate = async () => {
            await instance.initialize()
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    let accessToken: string = accessTokenResponse.accessToken
                    const config: AxiosRequestConfig = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + accessToken,
                        },
                        data: {},
                        params: {
                            date: format(month, 'yyyy-MM-dd'),
                            /*code: process.env.REACT_APP_BACKEND_URL*/
                        },
                    }

                    axios
                        .get(`${process.env.REACT_APP_BACKEND_URL}/Calendar`, config)
                        .then((response) => {
                            var newSelectedDate = response.data
                            setCalendarDates(newSelectedDate)
                            //dispatch(setSelectedDate(newSelectedDate))

                            /* if(newSelectedDate?.lines) {
                                setInitialLines(cloneDeep(newSelectedDate.lines))
                            } */

                            dispatch(setLoadingCalendar(false))
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {})
        }
        dispatch(setLoadingCalendar(true))
        getSelectedDate()
    }

    const getProjects = async (selectedDate: string) => {
        let newProjects: IProjects[]

        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                        date: selectedDate,
                    },
                }
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/Projects`, config)
                    .then((response) => {
                        newProjects = response.data
                        dispatch(setProjects(newProjects))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const loadCalendarDate = async (date: Date) => {
        try {
            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest)
            const accessToken = accessTokenResponse.accessToken
            const config: AxiosRequestConfig = {
                headers: { Authorization: 'Bearer ' + accessToken },
                params: { date: format(date, 'yyyy-MM-dd') },
            }

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Calendar/GetDate`, config)
            const newDateData = response.data

            setCalendarDates((prevDates) => {
                if (!prevDates) return [newDateData] // Initial state setup

                // Update or add new date data
                // does not work properly, UI does not get updated
                const updatedDates = prevDates.filter((d) => new Date(d.Date).getTime() !== new Date(newDateData.Date).getTime())
                updatedDates.push(newDateData)

                return updatedDates
            })
        } catch (error) {
            console.error('Error loading date:', error)
        } finally {
            dispatch(setLoadingCalendar(false))
        }
    }

    const getStatus = (date: Date) => {
        if (calendarDates) {
            var calendarDate = calendarDates.filter((d: any) => new Date(d.date).getDate() === date.getDate() && new Date(d.date).getMonth() === date.getMonth() && new Date(d.date).getFullYear() === date.getFullYear())[0]
            if (calendarDate) {
                return calendarDate.status
            }
        }
        return 'OPEN'
    }

    const getStatusString = (status: string) => {
        switch (status) {
            case 'OPEN':
                return t('calendar.open')
            case 'RELEASED':
                return t('calendar.released')
            case 'SAVED':
                return t('calendar.saved')
            case 'ABSENCE':
                return t('calendar.absence')
            case 'HOLIDAY':
                return t('calendar.holiday')
            case 'WEEKEND':
                return t('calendar.weekend')
            default:
                return ''
        }
    }

    const getStatusObj = (other: any, outsideCurrentMonth: any, day: any) => {
        const colors = {
            OPEN: {
                bgcolor: theme.palette.action.active,
                bordercolor: theme.palette.success.main,
                color: 'white',
            },
            SAVED: {
                bgcolor: '#ddf0dd',
                bordercolor: '#ddf0dd',
                color: theme.palette.success.main,
            },
            RELEASED: {
                bgcolor: theme.palette.success.main,
                bordercolor: theme.palette.success.main,
                color: 'white',
            },
            ABSENCE: {
                bgcolor: 'rgb(107, 0, 138)',
                bordercolor: 'rgb(107, 0, 138)',
                color: 'white',
            },
            HOLIDAY: {
                bgcolor: '#ECDEF1',
                bordercolor: '#ECDEF1',
                color: 'rgb(107, 0, 138)',
            },
            WEEKEND: {
                bgcolor: theme.palette.action.selected,
                bordercolor: theme.palette.action.selected,
                color: 'white',
            },
        }

        switch (getStatus(day)) {
            case 'SAVED':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.SAVED.bgcolor,
                            borderColor: colors.SAVED.bordercolor,
                            color: colors.SAVED.color,
                        }}
                    />
                )

            case 'RELEASED':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.RELEASED.bgcolor,
                            borderColor: colors.RELEASED.bordercolor,
                            color: colors.RELEASED.color,
                        }}
                    />
                )

            case 'ABSENCE':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.ABSENCE.bgcolor,
                            borderColor: colors.ABSENCE.bordercolor,
                            color: colors.ABSENCE.color,
                        }}
                    />
                )

            case 'HOLIDAY':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.HOLIDAY.bgcolor,
                            borderColor: colors.HOLIDAY.bordercolor,
                            color: colors.HOLIDAY.color,
                        }}
                    />
                )

            case 'OPEN':
                if (day.getDay() === 0 || day.getDay() === 6)
                    return (
                        <PickersDay
                            {...other}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            sx={{
                                bgcolor: colors.WEEKEND.bgcolor,
                                borderColor: colors.WEEKEND.bordercolor,
                            }}
                        />
                    )
                else
                    return (
                        <PickersDay
                            {...other}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            sx={
                                {
                                    //bgcolor: colors.WEEKEND.bgcolor,
                                    //borderColor: colors.WEEKEND.bordercolor,
                                }
                            }
                        />
                    )

            default:
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.WEEKEND.bgcolor,
                            borderColor: colors.WEEKEND.bordercolor,
                        }}
                    />
                )
        }
    }

    function ServerDay(props: any) {
        const { day, outsideCurrentMonth, ...other } = props

        return <Tooltip title={''}>{getStatusObj(other, outsideCurrentMonth, day)}</Tooltip>
    }

    useEffect(() => {
        if (!calendarDates && !loadingCalendar && !calendarInit) {
            var date = new Date()
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            loadCalendar(firstDay)
            setCalendarInit(true)
        }
    }, [loadingDate, loadingCalendar, calendarInit, calendarDates])

    return (
        <>
            <Box sx={{ textAlign: 'center', padding: 0 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                    {selectedDate?.header?.mserp_timeprofiledate || selectedJournal.header?.mserp_journalname ? (
                        <DateCalendar
                            views={['day', 'month', 'day']}
                            onChange={(value) => onDateChange(value)}
                            /* defaultValue={new Date(selectedDate?.header?.mserp_timeprofiledate)} */
                            //value={new Date(selectedDate.header.mserp_timeprofiledate)}
                            disabled={loadingDate || loadingCalendar}
                            slots={{
                                day: ServerDay,
                            }}
                            showDaysOutsideCurrentMonth
                            onMonthChange={(month) => loadCalendar(month)}
                            sx={{ width: 280 }}
                        />
                    ) : (
                        <>
                            <Skeleton variant="rounded" sx={{ width: 256, height: 36, m: '12px' }} />
                            <Skeleton variant="rounded" sx={{ width: 256, height: 24, m: '12px' }} />
                            <DayCalendarSkeleton sx={{ width: 280, height: 240, mt: '12px' }} />
                        </>
                    )}
                </LocalizationProvider>
                {/* <Button variant='contained' onClick={() => handleTestButtonClick()}>
                    Test
                </Button> */}
            </Box>

            <Box sx={{ textAlign: 'center', padding: 0 }}>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <FiberManualRecordOutlinedIcon color="disabled" sx={{ height: 8 }} />
                    {t('calendar.open')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <FiberManualRecordOutlinedIcon color="primary" sx={{ height: 8 }} />
                    {t('calendar.selected')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: '#ddf0dd' }} sx={{ height: 8 }} />
                    {t('calendar.saved')}
                </Box>
                <br />
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon color="success" sx={{ height: 8 }} />
                    {t('calendar.released')}
                </Box>

                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon color="disabled" sx={{ height: 8 }} />
                    {t('calendar.weekend')}
                </Box>
                <br />
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: '#ECDEF1' }} sx={{ height: 8 }} />
                    {t('calendar.holiday')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: 'rgb(107, 0, 138)' }} sx={{ height: 8 }} />
                    {t('calendar.absence')}
                </Box>
            </Box>
            {/* <p>{unsavedChanges ? "You have unsaved changes!" : "No unsaved changes" }</p> */}
        </>
    )
}

export default Calendar
