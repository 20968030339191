import { Box, CircularProgress, useTheme } from '@mui/material'
import Calendar from './Calendar'
import ZDEHeader from './ZDEHeader'
import JournalHeader from './Journal/JournalHeader'
import { IUser } from '../interface/IUser'
import { useSelector } from 'react-redux'

const Dashboard = () => {
    const theme = useTheme()
    const user: IUser = useSelector((state: any) => state.user?.value)

    const isInternal: boolean = (user?.worker?.mserp_employmentcategory && ((user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_INTERNAL) || (user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT))) ? true : false

    return (
        <Box display="flex" sx={{ flexDirection: { xs: 'column', lg: 'row'}, alignItems: { xs: 'center', lg: 'flex-start' }, justifyContent: { xs: 'flex-start', lg: 'center' } }}>
            <Box justifyContent="center" display="flex" bgcolor={theme.palette.background.default} sx={{ width: {xs: 'unset', lg: 400 }, flexGrow: 0, flexShrink: 0 }}>
                <Box paddingTop={2} paddingBottom={4}>
                    <Calendar />
                </Box>
            </Box>
            <Box bgcolor={theme.palette.background.paper} sx={{ minHeight: { xs: 'calc(100vh - 512px)', sm: 'calc(100vh - 520px)', lg: 'calc(100vh - 64px)' }, width: { xs: '100vw', lg: 'calc(100vw - 400px)' }, pb: isInternal ? { xs: '94px', md: '54px' } : { xs: '62px', md: '54px' } }}>
                {user.worker ? (
                    <Box padding={4}>{user?.worker?.mserp_usetimecard ? <ZDEHeader /> : <JournalHeader />}</Box> //TODO FAK View
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: { xs: isInternal ? 'calc(100vh - 606px)' : 'calc(100vh - 574px)', sm: isInternal ? 'calc(100vh - 614px)' : 'calc(100vh - 582px)', md: 'calc(100vh - 574px)', lg: 'calc(100vh - 118px)' } }} width="100%" flex="1 1 auto">
                        <CircularProgress color="inherit" />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Dashboard
