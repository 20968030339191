import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
    loadingCalendar: false,
}

export const loadingDateSlice: any = createSlice({
    name: 'LOADING_DATE',
    initialState,
    reducers: {
        setLoadingDate: (state: any, action: any) => {
            state.value = action.payload
        },
        setLoadingCalendar: (state: any, action: any) => {
            state.loadingCalendar = action.payload
        },
    },
})

export const { setLoadingDate, setLoadingCalendar } = loadingDateSlice.actions

export default loadingDateSlice.reducer
