import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { createBrowserHistory } from 'history'

let appInsights: ApplicationInsights | null = null

const browserHistory = createBrowserHistory()
var reactPlugin = new ReactPlugin()
// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin()
var clickPluginConfig = {
    autoCapture: true,
}

export const initializeApplicationInsights = () => {
    if (!appInsights && process.env.REACT_APP_APPINSIGHTS_KEY && process.env.REACT_APP_APPINSIGHTS_KEY.length > 0 && process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY && process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY.length > 0) {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
                connectionString: process.env.REACT_APP_APPINSIGHTS_KEY,
                // *** If you're adding the Click Analytics plug-in, delete the next line. ***
                //extensions: [reactPlugin],
                // *** Add the Click Analytics plug-in. ***
                extensions: [reactPlugin, clickPluginInstance],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                    // *** Add the Click Analytics plug-in. ***
                    [clickPluginInstance.identifier]: clickPluginConfig,
                },
            },
        })

        appInsights.loadAppInsights()
        console.log('Application Insights initialized.')
    }
}
