import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {
        header: {},
        lines: [],
    },
}

export const selectedDateSlice: any = createSlice({
    name: 'SELECTED_DATE',
    initialState,
    reducers: {
        setSelectedDate: (state: any, action: any) => {
            state.value = action.payload
        },
    },
})

export const { setSelectedDate } = selectedDateSlice.actions

export default selectedDateSlice.reducer
