import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
}

export const projects: any = createSlice({
    name: 'PROJECTS',
    initialState,
    reducers: {
        setProjects: (state: any, action: any) => {
            state.value = action.payload
        },
    },
})

export const { setProjects } = projects.actions

export default projects.reducer
