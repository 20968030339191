import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
}

export const alertSlice:any = createSlice({
    name: 'ALERT',
    initialState,
    reducers: {
        setAlert: (state: any, action: any) => {
            state.value = action.payload
        },
    },
})

export const { setAlert } = alertSlice.actions

export default alertSlice.reducer